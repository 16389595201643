import { render, staticRenderFns } from "./HistoricoPlanificaciones.vue?vue&type=template&id=365d9a4e&scoped=true&"
import script from "./HistoricoPlanificaciones.vue?vue&type=script&lang=js&"
export * from "./HistoricoPlanificaciones.vue?vue&type=script&lang=js&"
import style0 from "./HistoricoPlanificaciones.vue?vue&type=style&index=0&id=365d9a4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "365d9a4e",
  null
  
)

export default component.exports